import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import H2 from "../components/Objects/H2.jsx"
import { Link } from "gatsby"
const NotFound = () => {
  return (
    <>
      <section className="w-11/12 mx-auto flex flex-col items-center justify-center text-center py-14">
        <StaticImage
          src="../images/404.svg"
          alt="This pylon isn’t connected!"
          placeholder="blurred"
        />
        <H2 className="my-5 text-[#1D263A]">This pylon isn’t connected!</H2>
        <p className="text-[#1D263A] opacity-70">
          The page you are looking for is not <br /> available or doesn’t belong
          to this website!
        </p>
        <Link
          to="/"
          className="bg-primary text-white py-3 px-6 rounded-3xl  mt-12"
        >
          Go back to home
        </Link>
      </section>
    </>
  )
}
export default NotFound
