import React from "react"
import cn from "classnames"

const H2 = ({ className, children }) => {
  const headerClasses = cn(" text-3xl md:text-4xl", className)

  return <h2 className={headerClasses}>{children}</h2>
}

export default H2
